@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

/*-- VARIABLES CSS--*/
/*Colores*/
:root {
  --first-color: #e3f8ff;
  --second-color: #dcfafb;
  --third-color: #ffe8df;
  --accent-color: #ff5151;
  --dark-color: #161616;
}

/*Tipografia responsive*/
:root {
  --body-font: "Open Sans";
  --h1-font-size: 1.5rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --smaller-font-size: 0.75rem;
}

@media screen and (min-width: 768px) {
  :root {
    --h1-font-size: 2rem;
    --normal-font-size: 1rem;
    --smaller-font-size: 0.813rem;
  }
}

.product:hover {
  border: 2px solid white;
}

/*-- BASE --*/
*,
::after,
::before {
  box-sizing: border-box;
}

/* body{
    margin: 2rem 0 0 0;
    background-color: #fff;
    color: var(--dark-color);
    font-family: var(--body-font);
} */
h1 {
  font-size: var(--h1-font-size);
}

.logo a img {
  max-width: 90%;
  height: auto;
}

a {
  text-decoration: none;
}

/*-- LAYAOUT --*/
.main {
  padding: 2rem 0;
}

.bd-grid {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  max-width: 1200px;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  align-items: center;
  gap: 2rem;
}

/*-- PAGES --*/
.title-shop {
  position: relative;
  /* margin: 0 2.5rem; */
  /* margin-top: 3rem; */
}

.title-shop::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 72px;
  height: 2px;
  background-color: var(--dark-color);
  margin-left: 0.25rem;
}

/*-- COMPONENT --*/
.card {
  box-shadow: 0px 0px 5px -5px white;
  background-color: #69c7d3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  overflow: hidden;
}

.card__img {
  width: 100%;
  transition: 0.5s;
}

.card__img img {
  border-radius: 15px;
  height: 180px;
  width: 200px;
  margin-top: 10px;
}

.card__name {
  position: absolute;
  left: -32%;
  top: 0;
  width: 50px;
  height: 100%;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  background-color: rgb(24, 29, 69);
  color: #fff;
  font-weight: bold;
  transition: 0.5s;
  text-align: center;
}

.card__icon {
  font-size: 1.5rem;
  color: var(--dark-color);
}

.card__icon:hover {
  color: var(--accent-color);
}

.card__precis {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  transition: 0.5s;
}

.card__preci {
  display: block;
  text-align: center;
}

.card__preci--before {
  font-size: var(--smaller-font-size);
  color: var(--accent-color);
  margin-bottom: 0.25rem;
}

.card__preci--now {
  font-size: var(--h3-font-size);
  font-weight: bold;
}

/*Move left*/
.card:hover {
  box-shadow: 0 0.5rem 1rem #d1d9e6;
}

.card:hover .card__name {
  left: 0;
}

.card:hover .card__img {
  /* transform: rotate(30deg); */
  margin-left: 3.5rem;
}

.card:hover .card__precis {
  margin-left: 3.5rem;
  padding: 0 1.5rem;
}

/*-- FOOTER --*/

footer {
  text-align: center;
}

/*-- MEDIA QUERIES --*/
@media screen and (min-width: 1200px) {
  .title-shop {
    margin: 1rem;
  }

  .bd-grid {
    margin-left: auto;
    margin-right: auto;
  }
}

.bgimage {
  background-image: url("../../images/banner-bg3.c925f40f.jpg");
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: white;
  padding: 2rem;
}

.box:hover {
  background: #69c7d3;
  /* transform: 0.5s; */
  color: white;
  transition: 0.5s;
}

.box {
  padding: 2px;
  margin: 5px;
  background-color: white;
  transition: 0.125s ease;
  color: #181d45;
  transition: 0.5s;
}

.categoryImage {
  background-image: url("../../images/category.jpg");
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* border-radius: 32px; */
}

.overlay {
  background-color: rgba(0, 0, 0, 0.584);
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* border-radius: 32px; */
}

.CardContentBottom {
  color: black;
  font-size: 18px;
  font-weight: 700;
}

.bottomContent {
  font-size: 12px;
  color: white;
  max-width: 100%;
  margin: auto;
  margin-bottom: 10px;
}


.launch-buttons{
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .launch-buttons{
    justify-content: center;
  }
  .card__name {
    display: none !important;
  }

  .card__img {
    height: 100%;
  }

  .card__img img{
    height: 130px;
    width: 100%;
  }
  .card {
    padding: 0 0.3rem;
  }

  .bottomContent {
    margin: 0;
    font-size: 12px;
    color: white;
    max-width: 100%;
    margin-bottom: 0.5rem;
  }
}

/* App.css */
.sidebar {
  width: 100%;
  background-color: rgb(24, 29, 69);
  color: white;
  padding: 1rem;
  transition: 1s ease;
  line-height: 35px;
  border-radius: 32px;
}

.sidebar-category {
  border-bottom: 3px solid #69c7d3;
  transition: background-color 0.3s ease;
  margin-bottom: 15px;
}

.sidebar-category:hover {
  background-color: rgba(24, 29, 69, 0.752);
}

.dropdown {
  position: relative;
  display: inline-block;
  padding: 0px;
  display: none;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Add transition for background color */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-content.active {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-content .dropdown-item:hover {
  background-color: #f1f1f1;
}

.sub-menu {
  display: none;
  list-style-type: none;
  padding: 0;
}

.dropdown-item:hover .sub-menu {
  display: block;
}

.sidebar-items li {
  transition: 0.5s ease;
  margin-bottom: 5px;
  padding: 5px;
  list-style-type: none;
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  background-color: #69c7d3;
}
.sidebar-items li a{
  color: white !important;
}

.sidebar-items li:hover {
  background-color: #30c6da;
}

.sidebar-items {
  transition: 0.5s ease;
  margin: 0rem;
  padding: 0;
}

.viewButton {
  border: 1px solid;
  padding: 7px;
  width: 80px;
  background-color: #69c7d3;
  color: white;
  font-size: 10px;
  cursor: pointer;
}

.productCard {
  border: 1px solid lightgray;
  border-bottom: 10px solid #181d45;
  border-radius: 10px;
}