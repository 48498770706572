@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --font-family-poppin: "Poppins", sans-serif;
}

body{
  font-family: var(--font-family-poppin);
  margin: 0;
  padding: 0;
  background-color: rgba(128, 128, 128, 0.073);
}


.bread{
  background-image: url('./images/bannerImage.jpg');
  color: white;
  height: 100%;
  width: 100%;
}
.bread .breadcrumb-item a{
  color: white;
  text-decoration: none;
}
.breadCrumb_names{
  font-size: 20px;
}
.breadCrumb_names a{
  color: white;
}